.theme-colors {
  color: white;
}

.image-hover-effect {
  opacity: 100%;
  transition: opacity 0.3s ease;
}

.image-hover-effect:hover {
  opacity: 80%;
}
